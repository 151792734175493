// const changeColorHsl = (color: string, hue = null, saturation = null, lightness = null): string => {
//   if (!color) {
//     return "hsl(0, 0%, 100%)";
//   }

//   const chiffres = color.match(/\d+(\.\d+)?/g);
//   if (chiffres == null) {
//     return "hsl(0, 0%, 100%, 0)";
//   }
//   const nombres = chiffres.map(Number);

//   // Affichage du tableau de chiffres
//   let h = saturation != null ? Math.min(255, nombres[0] + saturation) : nombres[0];
//   let s = saturation ? Math.min(100, nombres[1] + saturation) : nombres[1];
//   let l = lightness ? Math.min(100, nombres[2] + lightness) : nombres[2];

//   const newHSL = `hsl(${nombres[0]}, ${s}%, ${l}%)`;
//   //   // Retourner la nouvelle couleur HSL
//   return newHSL;
// };

export const changeColorOpacity = (color?: string, opacity = 1): string | undefined => {
  if (!color) {
    return undefined;
  }

  const chiffres = color.match(/\d+(\.\d+)?/g);

  if (chiffres == null) {
    return "hsl(0, 0%, 100%, 0)";
  }

  const nombres = chiffres.map(Number);
  // Affichage du tableau de chiffres

  const newHSL = `hsl(${nombres[0]}, ${nombres[1]}%, ${nombres[2]}%, ${opacity})`;
  //   // Retourner la nouvelle couleur HSL
  return newHSL;
};

const updateHSL = (input: string, type: "h" | "s" | "l" | "a", operator = "+5") => {
  let output = input;
  if (type == "a") {
    input = eval(input + " *100");
  }
  let expected = eval(input + operator);

  if (type === "h") {
    if (expected > 355) {
      return 355;
    }
    if (expected < 0) {
      return 0;
    }
    return expected;
  }
  if (type === "s" || type === "l") {
    if (expected > 100) {
      return 100;
    }
    if (expected < 0) {
      return 0;
    }
    return expected;
  }
  if (type === "a") {
    if (expected > 100) {
      return 1;
    }
    if (expected < 0) {
      return 0;
    }
    return expected / 100;
  }

  return output;
};

export const modifyHslColor = (hslInput: string, modifyH = null, modifyS = null, modifyL = null, modifyOpacity = null): string | undefined => {
  if (!hslInput) {
    return undefined;
  }

  if (!(hslInput.includes("hsl(") || hslInput.includes("hsla("))) {
    console.error("Ceci n'est pas un HSL :" + hslInput);
  }

  const splited = hslInput.replace("hsl(", "").replace(")", "").split(",");

  let h = parseInt(splited[0]).toString();
  let s = parseInt(splited[1]).toString();
  let l = parseInt(splited[2]).toString();
  let a = splited[3] ? parseInt(splited[3]).toString() : "1";

  ////
  let newH = modifyH ? updateHSL(h, "h", modifyH) : h;
  let newS = modifyS ? updateHSL(s, "s", modifyS) : s;
  let newL = modifyL ? updateHSL(l, "l", modifyL) : l;
  let newA = modifyOpacity ? updateHSL(a, "a", modifyOpacity) : a;

  if (newA == 1) {
    return `hsl(${newH}, ${newS}%, ${newL}%)`;
  }
  //   // Retourner la nouvelle couleur HSL
  const newHSL = `hsla(${newH}, ${newS}%, ${newL}%, ${newA})`;
  return newHSL;
};

export function hslStringToHslObject(color?: string) {
  if (color?.includes("hsl") || color?.includes("hsla")) {
    const [h, s, l, a] = color
      .slice(color.indexOf("(") + 1, color.indexOf(")"))
      .split(",")
      .map((i) => parseInt(i));

    return { h, s, l, a };
  }
}
