import { QuestionBluePrintType } from "../../../../utilities/redux/types";
import { IconButtonIcons } from "../../..//components/icons";
import { Tooltip } from "../../../components/infos";
import { UserPicture } from "../../../components/pictures";
import { Badge, BadgeSize, BadgeStyle } from "../badge/Badge";

type Props = {
  bluePrint: QuestionBluePrintType;
  size?: BadgeSize;
  className?: string;
  style?: BadgeStyle;
};

const BluePrintCreatorBadge = ({ bluePrint, size = "medium", className = "", style = "gray2" }: Props) => {
  return (
    <>
      {bluePrint?.accountingFirm?.slug?.length > 0 ? (
        <Tooltip className={className} legend={"Créé par " + bluePrint?.createdBy?.firstName + " " + bluePrint?.createdBy?.lastName}>
          <Badge className="row a-center p-05" size={size} style={style}>
            <UserPicture size={25} user={bluePrint?.createdBy} />
            <p className="m-0 mr-1 fw-600 fs-85">
              {bluePrint?.createdBy?.firstName} {bluePrint?.createdBy?.lastName}
            </p>
          </Badge>
        </Tooltip>
      ) : (
        <Tooltip className={className} legend={"Créé par Syndemos"}>
          <Badge className="row a-center" size={size} iconSize={12} style={style}>
            <IconButtonIcons size={25} icon="syndemos" />
            <p className="m-0 mr-1 fw-600 fs-85">Syndemos</p>
          </Badge>
        </Tooltip>
      )}
    </>
  );
};

export default BluePrintCreatorBadge;
