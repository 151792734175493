import { IconButtonIcons } from "../../icons";
import { Badge, BadgeProps } from "../badge/Badge";

export const SyndemosBadge = (props: BadgeProps) => {
  return (
    <Badge withIcon {...props}>
      <IconButtonIcons icon="syndemos" />
      Syndemos
    </Badge>
  );
};
