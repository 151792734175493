import classNames from "classnames";
import { HTMLAttributes, type JSX } from "react";
import { CompanyType } from "../../../../utilities/redux/types";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { CompanyPicture } from "../../pictures";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (company: CompanyType) => void;
  selected?: boolean;
  backgroundColor?: CssVariablesType;
  pictureSize?: number;
  padding?: number;
  pictureDisplay?: boolean;
  fontSize?: number;
  fontWeight?: number;
  company: CompanyType | any;
  app: "expert" | "company" | "admin";
}

export function CompanyBadge({
  cb,
  className = "mt-0",
  fontSize = 0.8,
  fontWeight = 600,
  selected = false,
  backgroundColor = "gray1",
  padding = 5,
  pictureDisplay = true,
  pictureSize = 20,
  company,
  app,
  ...props
}: Props) {
  const Tag = `${cb ? "button" : "div"}` as keyof JSX.IntrinsicElements;

  return (
    <Tag
      onClick={() => cb && cb(company)}
      className={classNames("companyBadge o-hidden", className, { selected: selected }, { deleted: company?.deletedAt }, { clickable: cb })}
      style={{
        padding: padding,
        paddingRight: padding * 3,
        paddingLeft: pictureDisplay && company ? padding : padding * 3,
        backgroundColor: `var(--color-${backgroundColor})`,
        ...props?.style,
      }}
      tabIndex={cb && !selected ? 0 : -1}
    >
      {pictureDisplay && company && <CompanyPicture size={pictureSize} company={company} app={app} />}

      <p
        className="ellipsis"
        style={{
          margin: "0px",
          fontSize: fontSize + "rem",
          fontWeight: fontWeight,
          color: selected ? "white" : "var(--color-gray15)",
        }}
      >
        {company?.name || "Pas d'entreprise"}
      </p>

      {props?.children}
    </Tag>
  );
}
