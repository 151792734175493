"use client";

import classNames from "classnames";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { serverURL } from "../../../../utilities/config/server";
import { AccountingFirmType } from "../../../../utilities/redux/types";
import { MdModeEditOutline } from "../../../react-icons/md";
import { AccountingFirmPlaceholder } from "./AccountingFirmPlaceholder";

interface Props extends HTMLAttributes<HTMLDivElement> {
  accountingFirm?: AccountingFirmType;
  alt?: string;
  size?: number;
  className?: string;
  type?: "thumbnail" | "cover";
  app: "expert" | "company" | "admin";
  handleModify?: (e: any) => void;
  preview?: string;
};

export function AccountingFirmPicture({
  accountingFirm,
  size = 30,
  type = "thumbnail",
  app,
  preview,
  handleModify,
  children,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [preview, accountingFirm?.slug, accountingFirm?.isPictureDefined]);

  return (
    <div
      {...props}
      className={classNames(
        "picture accounting-firm-picture",
        { clickable: handleModify || props?.onClick },
        props?.className,
      )}
      style={{ width: size + "px", height: size + "px", ...props?.style }}
      onClick={handleModify
        ? (e) => {
          inputRef?.current?.click();
          props?.onClick && props?.onClick(e);
        }
        : props?.onClick}
    >

      {handleModify && <input
        ref={inputRef}
        hidden
        type="file"
        accept="image/*"
        onChange={(e: any) => handleModify(e?.target?.files)}
      />}
      {/*/////////////// Clickable ///////////////////////////////////*/}

      <div className="img-container">
        {accountingFirm?.slug && handleModify
          && <>
            <div className="cache" />
            <MdModeEditOutline className="icon modify" size={40} />
          </>}

        {((notFound == false && accountingFirm?.slug && accountingFirm?.isPictureDefined == true)
          || preview)
          ? <img
            src={
              preview
                ? preview
                : `${serverURL}/api/${app}/accounting_firm/${type}/${accountingFirm?.slug}?time=${accountingFirm?.updatedAt && Date.parse(new Date(accountingFirm?.updatedAt).toISOString())
                || accountingFirm?.createdAt && Date.parse(new Date(accountingFirm?.createdAt)?.toISOString())
                || null}`
            }
            aria-hidden
            onError={() => {
              setNotFound(true);
              return;
            }}
          />
          : <AccountingFirmPlaceholder />}
      </div>

      {children}
    </div>
  );
}
