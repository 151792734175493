'use client';

import { NotificationType } from "../../../../utilities/redux/types";
import { TbClockHour3 } from "../../../react-icons/tb";
import { Badge, BadgeProps } from "../badge/Badge";
import { LuMousePointerClick } from "../../../react-icons/lu";
import { TbMailOpened, TbAlertHexagonFilled, TbBounceRightFilled, TbMailOff } from "../../../react-icons/tb";
import { RiErrorWarningFill, RiMailSendLine } from "../../../react-icons/ri";
import { Tooltip } from "../../../components/infos";

interface Props extends Omit<BadgeProps, "children" | "style"> {
  status: NotificationType["status"];
  show?: boolean;
};

export function NotificationStatusBadge({ show = true, status, ...props }: Props) {
  const tooltipError = "Cela peut être dû à un blocage de la part du destinataire.";

  function getComputedStyle() {
    switch (status) {
      // SENT
      case "request":
        return {
          style: "main-light" as const,
          icon: <RiMailSendLine />,
          legend: "Envoyé",
        };

      case "sent":
        return {
          style: "main-light" as const,
          icon: <RiMailSendLine />,
          legend: "Envoyé",
        };

      // DELIVERED
      case "delivered":
        return {
          style: "main" as const,
          icon: <RiMailSendLine />,
          legend: "Reçu",
        };

      // CLICKED
      case "click":
        if (show) {
          return {
            style: "validated" as const,
            icon: <LuMousePointerClick />,
            legend: "Cliqué",
          };
        } else {
          return {
            style: "main" as const,
            icon: <RiMailSendLine />,
            legend: "Reçu",
          };
        }

      // OPENED
      case "unique_opened":
        return {
          style: "validated-plain" as const,
          icon: <TbMailOpened />,
          legend: "Ouvert",
        };

      case "opened":
        return {
          style: "validated-plain" as const,
          icon: <TbMailOpened />,
          legend: "Ouvert",
        };

      case "open":
        return {
          style: "validated-plain" as const,
          icon: <TbMailOpened />,
          legend: "Ouvert",
        };

      case "proxy_open":
        return {
          style: "validated-plain" as const,
          icon: <TbMailOpened />,
          legend: "Ouvert",
        };

      // NOT DELIVERED
      case "deferred":
        if (show) {
          return {
            style: "denied" as const,
            icon: <TbClockHour3 />,
            legend: "Différé",
          };
        } else {
          return {
            style: "denied" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "soft_bounce":
        if (show) {
          return {
            style: "denied" as const,
            icon: <TbBounceRightFilled />,
            legend: "Soft bounce",
          };
        } else {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "hard_bounce":
        if (show) {
          return {
            style: "denied-plain" as const,
            icon: <TbBounceRightFilled />,
            legend: "Hard bounce",
          };
        } else {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "spam":
        if (show) {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Spam",
          };
        } else {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "unsubscribed":
        if (show) {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Désinscrit",
          };
        } else {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "blocked":
        if (show) {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Bloqué",
          };
        } else {
          return {
            style: "denied-plain" as const,
            icon: <TbAlertHexagonFilled />,
            legend: "Non reçu",
            tooltip: true
          };
        }

      case "error":
        return {
          style: "denied-plain" as const,
          icon: <RiErrorWarningFill />,
          legend: "Erreur",
        };

      case "invalid":
        return {
          style: "archived-plain" as const,
          icon: <TbMailOff />,
          legend: "Mail invalide",
        };
      case "invalid_email":
        return {
          style: "archived-plain" as const,
          icon: <TbMailOff />,
          legend: "Mail invalide",
        };

      default:
        if (show) {
          return {
            style: "empty" as const,
            legend: "Status non renseigné",
          };
        } else {
          return {
            style: "empty" as const,
            legend: null,
          };
        }
    }
  }

  const computedStyle = getComputedStyle();

  if (computedStyle?.legend)
    return (
      <Tooltip legend={computedStyle?.tooltip ? tooltipError : ""}>
        <Badge {...props} style={computedStyle.style} withIcon={!!computedStyle?.icon}>
          {!!computedStyle?.icon && computedStyle?.icon}
          {computedStyle?.legend}
        </Badge>
      </Tooltip>
    );
  else return <></>;
}