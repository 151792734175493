"use client";

import { UserTeamType } from "../../../../utilities/redux/types";
import UserTeamIconItem from "../../icons/userTeamIcon/UserTeamIconItem";
import { Badge, BadgeProps, } from "../badge/Badge";

interface Props extends Omit<BadgeProps, "children"> {
  userTeam: UserTeamType;
  clickable?: boolean;
};

export const UserTeamBadge = ({ userTeam, size = "medium", clickable = false, ...props }: Props) => {
  return (
    <>
      {userTeam &&
        <Badge
          {...props}
          onClick={
            (clickable && userTeam?.slug)
              ? () => window.open("/dashboard/gestion-cabinet/collaborateurs/equipes/" + userTeam?.slug, "_blank", "noopener,noreferrer")
              : undefined
          }
          withIcon
          styleCSS={{
            ...props?.styleCSS,
            background: userTeam?.color,
            color: "white",
            opacity: 1,
          }}
          size={size}
        >
          <UserTeamIconItem value={userTeam?.icon} />
          <p className="ellipsis m-0 p-0" style={{ color: "inherit", fontSize: "inherit" }}>
            {userTeam?.name}
          </p>
        </Badge>}
    </>
  );
};
