"use client";
import classNames from "classnames";

import type { CSSProperties } from "react";

export type BadgeSize = "small" | "medium" | "big" | "large";
export type BadgeStyle = "main" | "main-light" | "gray" | "gray2" | "validated" | "validated-plain" | "denied" | "denied-plain" | "archived" | "archived-plain" | "empty" | "dark";

export type BadgeProps = {
  children: any | any[];
  style?: BadgeStyle;
  withIcon?: boolean;
  iconSize?: number;
  className?: string;
  size?: BadgeSize;
  onMouseEnter?: (arg: any) => void;
  onMouseLeave?: (arg: any) => void;
  gap?: number;
  onClick?: (e?: any) => void;

  height?: string;
  width?: string;
  styleCSS?: CSSProperties;
};

export function Badge({
  children,
  styleCSS,
  style = "gray2",
  onMouseEnter,
  onMouseLeave,
  gap = 5,
  withIcon = false,
  iconSize = 20,
  className,
  size = "small",
  onClick,
  height = "fit-content",
  width = "fit-content",
  ...props
}: BadgeProps) {
  return (
    <div
      {...props}
      onClick={(e) => onClick && onClick(e)}
      className={classNames("badge", style, { clickable: !!onClick }, className, size)}
      style={{
        display: "grid",
        gridTemplateColumns: withIcon === true ? `${iconSize}px 1fr` : "1fr",
        alignItems: "center",
        gap: gap + "px",

        width,
        height,
        ...styleCSS,
      }}
      tabIndex={onClick && !className?.includes("selected") ? 0 : -1}
      role={onClick && !className?.includes("selected") ? "button" : "div"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {children}
    </div>
  );
}
