import React from "react";
import { StripePaymentBadge } from "../stripePaymentBadge/StripePaymentBadge";
import { Badge } from "../badge/Badge";
import { InvoiceType } from "../../../../utilities/redux/types";

type Props = {
  invoice: InvoiceType;
  size?: "medium" | "small" | "big";
};

export function InvoicePaymentBadge({ invoice, size = "medium" }: Props) {
  return (
    <>
      {invoice?.payment?.status ? (
        <StripePaymentBadge size={size} status={invoice?.payment?.status} />
      ) : invoice?.paidAt ? (
        <Badge size={size} style="validated">
          Payé hors SaaS
        </Badge>
      ) : (
        <Badge size={size}>Pas de paiement</Badge>
      )}
    </>
  );
}
