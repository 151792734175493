import { StatusType } from "../../ui/components/badges";
import { QuestionType } from "../redux/types";

export function makeId(str: string | number) {
  return str
    ?.toString()
    ?.toLowerCase()
    ?.replace(/[{}:]/g, "")
    ?.replace(/[\/\*\+\-()]/g, "-")
    ?.replace(/ |"|'/g, "_");
}

export function uuidv4(): string {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) => (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16));
}

export const cutString = (str: string, char = 50) => {
  return str.slice(0, char) + "...";
};

export const getQuestionStatus = (question: QuestionType): StatusType => {
  const { isValidated, isSentToClient, isAnswerByClient, isSentByClient, isProcessed, isDenied, isArchived } = question;
  if (isArchived == true) {
    return { value: "archived", legend: "Archivée", style: "denied" };
  }

  if (isValidated == false && isSentToClient == false && isAnswerByClient == false && isSentByClient == false && isProcessed == false && isDenied == false && isArchived == false) {
    return { value: "created", legend: "Rédigée", style: "main-light" };
  }
  if (isValidated == true && isSentToClient == false && isAnswerByClient == false && isSentByClient == false && isProcessed == false && isDenied == false && isArchived == false) {
    return { value: "validated", legend: "Validée", style: "validated" };
  }
  if (isValidated == true && isSentToClient == true && isAnswerByClient == false && isSentByClient == false && isProcessed == false && isDenied == false && isArchived == false) {
    return {
      value: "send_to_client",
      legend: "Envoyée au client",
      style: "main",
    };
  }
  if (isValidated == true && isSentToClient == true && isAnswerByClient == true && isSentByClient == false && isProcessed == false && isDenied == false && isArchived == false) {
    return {
      value: "answered_by_client",
      legend: "Répondue par le client",
      style: "main",
    };
  }
  if (isValidated == true && isSentToClient == true && isAnswerByClient == true && isSentByClient == true && isProcessed == false && isDenied == false && isArchived == false) {
    return {
      value: "to_process",
      legend: "A traiter",
      style: "main",
    };
  }
  if (isValidated == true && isSentToClient == true && isAnswerByClient == false && isSentByClient == false && isProcessed == false && isDenied == true && isArchived == false) {
    return { value: "denied", legend: "Rejetée", style: "denied" };
  }
  if (isValidated == true && isSentToClient == true && isAnswerByClient == true && isSentByClient == true && isProcessed == true && isDenied == false && isArchived == false) {
    return { value: "accepted", legend: "Acceptée", style: "validated" };
  }

  return { value: "default", legend: "Default", style: "gray" };
};

// function isSiret(siret: string) {
//   let estValide;
//   if ( (siret.length != 14)  )
//     estValide = false;
//   else {
//      // Donc le SIRET est un numérique à 14 chiffres
//      // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
//      // correspondent au numéro d'établissement
//      // et enfin le dernier chiffre est une clef de LUHN.
//     let somme = 0;
//     let tmp;
//     for (let cpt = 0; cpt<siret.length; cpt++) {
//       if ((cpt % 2) == 0) { // Les positions impaires : 1er, 3è, 5è, etc...
//         // tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
//         if (tmp > 9)
//           tmp -= 9;    // Si le résultat est supérieur à 9, on lui soustrait 9
//       }
//      else
//        tmp = siret.charAt(cpt);
//        somme += parseInt(tmp);
//     }
//     if ((somme % 10) == 0)
//       estValide = true; // Si la somme est un multiple de 10 alors le SIRET est valide
//     else
//       estValide = false;
//   }
//   return estValide;
// }

export function capitalize(str: string) {
  const result = str.charAt(0).toUpperCase() + str.slice(1);

  return result;
}
