"use client";

import classNames from "classnames";
import { HTMLAttributes } from "react";
import { AccountingFirmType } from "../../../../utilities/redux/types";
import { AccountingFirmPicture } from "../../pictures";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (accountingFirm: AccountingFirmType) => void;
  selected?: boolean;
  backgroundColor?: string;
  pictureSize?: number;
  padding?: number;
  pictureDisplay?: boolean;
  fontSize?: number;
  fontWeight?: number;
  accountingFirm: AccountingFirmType | any;
  app: "expert" | "company" | "admin";
}

export function AccountingFirmBadge({
  cb,
  className = "mt-0",
  fontSize = 0.8,
  fontWeight = 600,
  selected = false,
  backgroundColor = "gray1",
  padding = 5,
  pictureDisplay = true,
  pictureSize = 20,
  accountingFirm,
  app,
  ...props
}: Props) {
  return (
    <div
      onClick={() => cb && cb(accountingFirm)}
      className={classNames("accountingFirmBadge o-hidden", className, { selected: selected }, { deleted: accountingFirm?.deletedAt }, { clickable: cb })}
      style={{
        padding: padding,
        paddingRight: padding * 3,
        paddingLeft: pictureDisplay && accountingFirm?.slug ? padding : padding * 3,
        maxWidth: "100%",
        backgroundColor: `var(--color-${backgroundColor})`,
        ...props?.style,
      }}
      role={cb ? "button" : "div"}
    >
      {pictureDisplay && accountingFirm && <AccountingFirmPicture size={pictureSize} accountingFirm={accountingFirm} app={app} />}

      <p
        className="ellipsis"
        style={{
          margin: "0px",
          fontSize: fontSize + "rem",
          fontWeight: fontWeight,
          color: selected ? "white" : "var(--color-gray15)",
        }}
      >
        {accountingFirm?.name || "Pas de cabinet en lien"}
      </p>

      {props?.children}
    </div>
  );
}
