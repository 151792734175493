"use client";

import classNames from "classnames";
import { Fragment, HTMLAttributes, useEffect, useState, type JSX } from "react";
import { Tooltip } from "ui/components/infos";
import { Badge, BadgeSize, BadgeStyle } from "../badge/Badge";

type TabType = {
  legend: string;
  value: string;
  icon?: JSX.Element;
  disabled?: boolean;
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (arg: string) => void;
  className?: string;
  defaultValue?: TabType["value"];
  tabs: TabType[];
  BadgeStyle?: BadgeStyle;
  BadgeSize?: BadgeSize;
  type?: "badge" | "icon";
}

export function TabBadge({ tabs, cb, defaultValue, BadgeSize = "medium", className, type = "badge", ...props }: Props) {
  const [tabSelected, setTabSelected] = useState<string>(defaultValue ?? tabs[0].value);

  useEffect(() => {
    setTabSelected(defaultValue ?? tabs[0].value);
  }, [defaultValue]);

  return (
    <div
      {...props}
      className={classNames(
        "row wrap",
        className
      )}
      style={{ gap: "5px", ...props.style }}
    >
      {tabs?.length > 0 &&
        tabs?.map((tab) => (
          <Fragment
            key={`tab_${tab.value}`}
          >
            {type === "icon"
              && <Tooltip
                legend={tab.legend}
                className={classNames(
                  "m-0 tile thin tile-choice white br-5",
                  { clickable: tab.disabled != true },
                  { selected: tab.value === tabSelected }
                )}
                onClick={() => {
                  if (tab.disabled != true) {
                    cb && cb(tab.value);
                    setTabSelected(tab.value);
                  }
                }}
              >
                {tab.icon}
              </Tooltip>}

            {type === "badge"
              && <Badge
                style={tabSelected == tab.value ? "dark" : "gray"}
                onClick={() => {
                  if (tab.disabled != true) {
                    cb && cb(tab.value);
                    setTabSelected(tab.value);
                  }
                }}
                className={classNames("row a-center gap-1", { selected: tabSelected == tab.value })}
                size={BadgeSize}
                height="unset"
              >
                {tab.icon}
                {tab.legend}
              </Badge>}
          </Fragment>
        ))}
    </div>
  );
}
