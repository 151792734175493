import { UserType } from "../../../../utilities/redux/types";
import { Badge, BadgeProps, } from "../badge/Badge";

interface Props extends Omit<BadgeProps, "children"> {
  user: UserType;
  className?: string;
};

export const UserExpertFonctionBadge = ({ user, size = "medium", style = "gray", ...props }: Props) => {

  return (
    <>
      {user?.fonction && (
        <Badge {...props} size={size} style={style}>
          <p className="ellipsis m-0 p-0" style={{ color: "inherit", fontSize: "inherit" }}>
            {user?.fonction?.name}
          </p>
        </Badge>
      )}
    </>
  );
};
