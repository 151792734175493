"use client";
import { ReactNode, useRef, useState, type JSX } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Modal } from "../../modals";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Badge } from "ui/components/badges";
import { IconButtonIcons } from "ui/components/icons";
import IndicatorCount from "ui/components/infos/indicatorCount/IndicatorCount";
import { Preview } from "..";
import { GalleryPreviewSlide } from "./GalleryPreviewSlide";

type Props = {
  children: ReactNode | ReactNode[] | JSX.Element | JSX.Element[] | any;
  handleDownload?: boolean;
  fetcher: any;
  maxNumber?: number;
  maxNumberPreviewStyle?: "badge" | "skeleton";
};

export function GalleryPreview({ children, handleDownload = false, fetcher, maxNumber, maxNumberPreviewStyle = "skeleton" }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pictureIndex, setPictureIndex] = useState<number>(0);

  const previews = (Array.isArray(children) ? children : [children]).flat();

  function onClick(e: any, index: number) {
    e.stopPropagation();
    setIsModalOpen(true);
    setPictureIndex(index);
  }
  const modalRef = useRef(null);

  return (
    <>
      {((typeof maxNumber == "number") && maxNumber >= 0 && previews?.length > maxNumber)
        ? <>
          {maxNumberPreviewStyle === "badge"
            && <Badge className="pr-1" style="empty" withIcon onClick={(e) => onClick(e, 0)}>
              <IconButtonIcons icon="attachment" /> {previews?.length}
            </Badge>}

          {maxNumberPreviewStyle === "skeleton"
            && <IndicatorCount
              number={previews?.length}
              top="5px"
              right="5px"
              bottom="unset"
            >
              <div className="preview clickable" onClick={(e) => onClick(e, 0)}>
                <div className="img-container flex a-center j-center">
                  <IconButtonIcons icon="attachment" size={25} />
                </div>
              </div>
            </IndicatorCount>}
        </>
        : previews?.map((preview, index) => preview && <Preview {...preview?.props} onClick={(e) => onClick(e, index)} key={"preview-" + index} />)}

      <Modal showModal={isModalOpen} setShowModal={setIsModalOpen} id="galleryModal">
        <Swiper
          ref={modalRef}
          initialSlide={pictureIndex}
          spaceBetween={15}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className="gallerySwiper"
        >
          {previews?.map((preview, index) => (
            <SwiperSlide key={`slide_${index}`}>
              <GalleryPreviewSlide {...preview?.props} handleDownload={handleDownload} fetcher={fetcher} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </>
  );
}
