import { CssVariablesType } from "ui/styles/CSSVariablesTypes";
import { parseDate } from "../../../../utilities/tools/date";
import { FaPowerOff } from "../../../react-icons/fa";
import { ImBin2, ImBlocked } from "../../../react-icons/im";
import { IoIosSend } from "../../../react-icons/io";
import { IoAddCircleOutline } from "../../../react-icons/io5";
import { LuRefreshCcw } from "../../../react-icons/lu";
import { MdOutlineAttachMoney, MdPaid } from "../../../react-icons/md";
import { TbArrowsExchange } from "../../../react-icons/tb";
import { Tooltip } from "../../infos";
import { Badge, BadgeProps, BadgeStyle } from "../badge/Badge";

import type { JSX } from "react";

interface Props extends BadgeProps {
  showTooltip?: boolean;
  tooltipPosition?: "top" | "left" | "bottom" | "right";
  customIcon?: JSX.Element;
  customError?: string;
  customLegend?: string;
  iconSize?: number;

  type?: "created" | "connection" | "updated" | "deleted" | "closed" | "disabled" | "paid" | "send" | "exchange" | "startBillingAt" | "blocked" | "custom";
  children: string | Date | null;

  showDate?: boolean;
  showHour?: boolean;
  iconColor?: CssVariablesType;
}

export function DateBadge({
  children,
  customIcon,
  customError = "",
  customLegend = "",
  type = "created",
  iconSize = 20,
  className,
  size = "medium",
  tooltipPosition = "bottom",
  showTooltip = true,
  showDate = true,
  showHour = false,
  iconColor,
  ...props
}: Props) {
  const color = iconColor ? `var(--color-${iconColor})` : null;

  const date = parseDate(children, showDate, showHour);

  const computedStyle = (): BadgeStyle => {
    if (type === "deleted" || type === "blocked") {
      if (children !== null) {
        return "denied";
      } else {
        return "gray";
      }
    } else if (type === "paid") {
      if (children !== null) {
        return "validated";
      } else {
        return "denied";
      }
    } else {
      return props?.style ?? "gray";
    }
  };

  return (
    <Tooltip
      style={props?.styleCSS}
      legend={
        showTooltip
          ? (type === "created" && "Date de création") ||
          (type === "startBillingAt" && "Début de facturation") ||
          (type === "connection" && "Dernière connexion") ||
          (type === "updated" && "Date de mise à jour") ||
          (type === "deleted" && "Date de suppression") ||
          (type === "disabled" && "Date de désactivation") ||
          (type === "closed" && "Date de clôture") ||
          (type === "paid" && "Date de paiement") ||
          (type === "send" && "Date d'envoi") ||
          (type === "exchange" && "Dernier échange envoyé") ||
          (type === "blocked" && "Date de blocage") ||
          (type === "custom" && customLegend && customLegend)
          : ""
      }
      position={tooltipPosition}
      className={className}
    >
      <Badge {...props} style={computedStyle()} iconSize={iconSize} size={size} withIcon>
        {type === "startBillingAt" && <MdOutlineAttachMoney color={color ?? "var(--color-success)"} />}
        {type === "created" && <IoAddCircleOutline color={color ?? "var(--color-main)"} />}
        {type === "updated" && <LuRefreshCcw color={color ?? "var(--color-warning)"} />}
        {(type === "disabled" || type === "closed" || type === "blocked") && <ImBlocked color={color ?? "var(--color-error)"} />}
        {type === "deleted" && <ImBin2 color={color ?? "var(--color-error)"} />}
        {type === "connection" && <FaPowerOff color={color ?? !children ? "var(--color-error)" : "var(--color-success)"} />}
        {type === "paid" && <MdPaid color={color ?? !children ? "var(--color-error)" : "var(--color-success)"} />}
        {type === "send" && <IoIosSend color={color ?? !children ? "var(--color-main)" : ""} />}
        {type === "exchange" && <TbArrowsExchange color={color} />}
        {type === "custom" && customIcon && customIcon}

        {date !== "Pas de date"
          ? date
          : (type === "created" && "Non créé") ||
          (type === "connection" && "Pas de connexion") ||
          (type === "updated" && "Non mis à jour") ||
          (type === "deleted" && "Non supprimé") ||
          (type === "disabled" && "Non désactivé") ||
          (type === "closed" && "Non clôturé") ||
          (type === "paid" && "Impayé") ||
          (type === "exchange" && "Pas d'envoi") ||
          (type === "blocked" && "Non bloqué") ||
          (type === "send" && "Pas d'envoi") ||
          (type === "custom" && customError && customError)}
      </Badge>
    </Tooltip>
  );
}
