import React from "react";
import { Badge, BadgeSize, BadgeStyle } from "../badge/Badge";

type Props = {
  status: "succeeded" | "canceled" | "processing" | "pending" | "requires_action" | "requires_capture" | "requires_payment_method" | "payment_failed";
  size?: BadgeSize;
};

export function StripePaymentBadge({ status, size = "medium" }: Props) {
  const computeStripePaymentStatus = (): BadgeStyle => {
    if (status === "succeeded") {
      return "validated";
    }
    if (status === "canceled") {
      return "denied";
    }
    if (status === "requires_action" || "requires_capture" || "requires_confirmation" || "requires_payment_method") {
      return "archived";
    }

    return "gray";
  };

  return (
    <Badge size={size} className="mb-0" style={computeStripePaymentStatus()}>
      {status === "succeeded" && "Succès"}
      {status === "canceled" && "Annulé"}
      {status === "processing" && "En cours..."}
      {status === "pending" && "En cours..."}
      {status === "requires_action" && "Requiert une action de votre part"}
      {status === "requires_capture" && "Requiert une action de votre part"}
      {status === "requires_payment_method" && "Requiert un moyen de paiement valide"}
    </Badge>
  );
}
