"use client";

import classNames from "classnames";
import { HTMLAttributes, useState } from "react";
import { QuestionCategoryType } from "../../../../utilities/redux/types";
import { changeColorOpacity } from "../../../../utilities/tools/color";
import { Grid } from "../../../components/blocs";
import { CategoryIcon } from "../../../components/icons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  category: QuestionCategoryType;
  selected?: boolean;
  iconSize?: number;
}

export function CategoryBadge({ category, selected = false, iconSize = 15, ...props }: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Grid
      {...props}
      onMouseEnter={(e) => {
        if (props?.onClick) {
          setIsHover(true);
          props.onMouseEnter && props.onMouseEnter(e);
        }
      }}
      onMouseLeave={(e) => {
        if (props?.onClick) {
          setIsHover(false);
          props.onMouseLeave && props.onMouseLeave(e);
        }
      }}
      template="auto 1fr"
      alignItems="center"
      mediaQuery={0}
      style={{
        backgroundColor:
          category.color &&
          (selected || !props?.onClick
            ? isHover
              ? changeColorOpacity(category.color, 0.2)
              : changeColorOpacity(category.color, 0.12)
            : isHover && changeColorOpacity(category.color, 0.08)),

        borderColor: category?.color && (selected ? "transparent" : changeColorOpacity(category.color, 0.4)),
        color: category?.color,
        ...props.style,
      }}
      className={classNames("badge empty medium fit-content", { clickable: props?.onClick, selected }, props.className)}
    >
      <CategoryIcon iconName={category?.icon} size={iconSize} color={category?.color} />

      {category?.title}
    </Grid>
  );
}
