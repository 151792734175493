"use client";

import classNames from "classnames";
import { HTMLAttributes, useEffect, useRef, useState } from "react";

import { serverURL } from "../../../../utilities/config/server";
import { CompanyPermissionType, UserType } from "../../../../utilities/redux/types";
import { MdModeEditOutline } from "../../../react-icons/md";
import { UserCompanyPermissionIcon, UserExpertRoleIcon } from "../../icons";
import { UserPlaceholder } from "./UserPlaceholder";

interface Props extends HTMLAttributes<HTMLDivElement> {
  user?: Partial<UserType>;
  // email?: string;
  // isPictureDefined?: boolean;
  // updatedAt?: string;
  companyPermission?: CompanyPermissionType;
  alt?: string;
  size?: number;
  sizeExpertRoleIcon?: number;
  isIconExpertRole?: boolean;
  className?: string;
  handleModify?: (e: any) => void;
  preview?: string;
  type?: "thumbnail" | "profil";
}

export function UserPicture({
  user,
  companyPermission,
  size = 30,
  sizeExpertRoleIcon = 30,
  isIconExpertRole = false,
  className = "",
  type = "thumbnail",
  preview,
  handleModify,
  children,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [user?.isPictureDefined, user?.email, preview]);

  return (
    <div
      {...props}
      className={classNames("profil-picture picture", className, { clickable: handleModify })}
      style={{ width: size + "px", height: size + "px", ...props.style }}
      onClick={handleModify ? () => inputRef?.current?.click() : () => { }}
    >
      {/*/////////////// Clickable ///////////////////////////////////*/}

      {handleModify && <MdModeEditOutline className="modify icon" size={40} />}

      {handleModify && <input ref={inputRef} hidden type="file" accept="image/*" onChange={handleModify ? (e: any) => handleModify(e?.target?.files) : () => { }} />}

      <div className="img-container">
        {handleModify && <div className="cache" />}

        {user?.isPictureDefined == true && user?.email && notFound == false ? (
          <>
            <img
              // fill
              src={
                preview ?? `${serverURL}/api/user/${type}/${btoa(user?.email)}?time=${user?.updatedAt && Date.parse(new Date(user?.updatedAt).toISOString())
                || user?.createdAt && Date.parse(new Date(user?.createdAt)?.toISOString())
                || null}}`
              }
              aria-hidden
              onError={() => {
                setNotFound(true);
              }}
            />
          </>
        ) : (
          <UserPlaceholder user={user} size={size} />
        )}
      </div>

      {companyPermission && <UserCompanyPermissionIcon companyPermission={companyPermission} size={Math.floor(size * 0.4)} />}

      {children}

      {isIconExpertRole && (user?.roles?.includes("ROLE_EXPERT") || user?.roles?.includes("ROLE_EXPERT_OWNER")) && <UserExpertRoleIcon size={sizeExpertRoleIcon} user={user} />}
    </div>
  );
}
