import { FaStar, FaUserAlt } from "../../../react-icons/fa";
import { UserType } from "../../../../utilities/redux/types";
import { Badge, BadgeSize, BadgeStyle } from "../badge/Badge";

type Props = {
  user: UserType;
  size?: BadgeSize;
  className?: string;
  type?: BadgeStyle;
};

export const UserExpertRoleBadge = ({ user, size = "big", className, type = "gray" }: Props) => {
  return (
    <Badge size={size} withIcon className={"mt-0 mb-0 " + className} style={type}>
      {user?.roles?.includes("ROLE_EXPERT_OWNER") ? <FaStar color="var(--color-warning)" /> : <FaUserAlt color="var(--color-active)" />}
      {user?.roles?.includes("ROLE_EXPERT_OWNER") ? "Administrateur" : "Utilisateur"}
    </Badge>
  );
};

UserExpertRoleBadge;
