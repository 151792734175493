"use client";

import React, { useEffect, useState } from "react";
import { QuestionType } from "../../../../utilities/redux/types";
import { getQuestionStatus } from "../../../../utilities/tools/string";

import { Badge, BadgeStyle } from "../badge/Badge";
import { QuestionStatusIcon } from "../../icons";

export type StatusType = {
  legend: string;
  value: "default" | "archived" | "created" | "validated" | "send_to_client" | "answered_by_client" | "to_process" | "denied" | "accepted" | null;
  style: BadgeStyle;
};

type Props = {
  question: QuestionType | any;
};

export function QuestionStatusBadge({ question }: Props) {
  const [status, setStatus] = useState<StatusType>({
    legend: "",
    value: null,
    style: "gray",
  });
  const ICON_SIZE = 16;

  useEffect(() => {
    setStatus(getQuestionStatus(question));
  }, [question]);

  return (
    <Badge iconSize={ICON_SIZE} withIcon={true} style={status.style}>
      <QuestionStatusIcon icon={status.value} />
      {status.legend}
    </Badge>
  );
}
