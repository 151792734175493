import React from "react";
import { Badge, BadgeSize, BadgeStyle } from "../badge/Badge";

type Props = {
  status: "payment_intent.canceled" |
  "payment_intent.payment_failed" |
  "payment_intent.created" |
  "charge.failed" |
  "payment_intent.partially_funded" |
  "payment_intent.processing" |
  "payment_intent.requires_action" |
  "charge.pending" |
  "balance.available" |
  "charge.refunded" |
  "payment_intent.succeeded" |
  "charge.succeeded" |
  "succeeded" |
  "charge.updated" |
  "setup_intent.succeeded" |
  "payment_method.attached" |
  "setup_intent.created" |
  "customer.updated" |
  "customer.created";
  size?: BadgeSize;
};

export function EventStripeBadge({ status, size = "medium" }: Props) {
  const computeStyle = (): BadgeStyle => {
    switch (status) {
      case "payment_intent.canceled":
      case "payment_intent.payment_failed":
      case "charge.failed":
        return "denied";

      case "payment_intent.partially_funded":
      case "payment_intent.processing":
      case "payment_intent.requires_action":
      case "charge.pending":
      case "balance.available":
      case "charge.refunded":
        return "archived";

      case "payment_intent.succeeded":
      case "charge.succeeded":
      case "succeeded":
        return "validated";
    }

    return "gray";
  };

  return (
    <Badge style={computeStyle()} size={size}>
      {status === "payment_intent.canceled" && "Tentative de paiement annulée"}
      {status === "payment_intent.created" && "Tentative de paiement créé"}
      {status === "payment_intent.partially_funded" &&
        "Tentative de paiement partiellement payée"}
      {status === "payment_intent.payment_failed" &&
        "Tentative de paiement échouée"}
      {status === "payment_intent.processing" &&
        "Tentative de paiement en cours"}
      {status === "payment_intent.requires_action" &&
        "Tentative de paiement requiert une action supplémenatire"}
      {status === "payment_intent.succeeded" && "Tentative de paiement réussie"}
      {status === "charge.failed" && "Charge échouée"}
      {status === "charge.updated" && "Charge mise à jour"}
      {status === "charge.pending" && "Charge en cours"}
      {status === "charge.succeeded" && "Charge executée"}
      {status === "balance.available" && "Balance disponible"}
      {status === "charge.refunded" && "Charge refunded"}
      {status === "setup_intent.succeeded" &&
        "Prélèvement mis en place avec succès"}
      {status === "payment_method.attached" && "Méthode de paiement attachée"}
      {status === "setup_intent.created" && "Prélèvement initialisé"}
      {status === "customer.updated" && "Utilisateur mis à jour"}
      {status === "customer.created" && "Utilisateur créé"}
    </Badge>
  );
}
